import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getProducts } from '../actions/productActions';
import toast from 'react-hot-toast';


const ProductImport = () => {

    const host = process.env.REACT_APP_API_HOST;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fileLoading, setFileLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    const handleSave = async (e) => {
        setFileLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 92);
                    setProgress(progress);
                },
            };

            axios.put(`${host}/products/import`, formData, config)
                .then((res) => {
                    dispatch(getProducts());
                    setFileLoading(false);
                    setProgress(100);
                    navigate('/products');
                    toast.success('Products imported successfully!');
                })
                .catch((err) => {
                    setProgress(0);
                    setFileLoading(false);
                    toast.error('Error importing products!');
                })
                .finally(() => {
                    setProgress(0);
                    setFileLoading(false);
                });
        } catch (e) {
            setFileLoading(false);
            toast.error('Unexpected error occurred!');
        }
    };


    const now = progress;

    return (
        <>
            <div className="content-wrapper">
                {
                    <div className="row p-5 justify-content-center">
                        <div className="col-md-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Import Products</h5>
                                </div>
                                <div className="card-body">
                                    <form encType='multipart/form-data'>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="importFile" className='mb-2'>Upload CSV</label>
                                                    <input type='file' className='form-control mb-3' name="file" onChange={handleChange} accept=".csv" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            {fileLoading ? (<ProgressBar now={now} label={`${now}%`} />
                                            ) : (null)}
                                        </div>
                                        <button type="submit" onClick={handleSave} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                            Import
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default ProductImport