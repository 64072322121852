import { React, useState } from 'react'
import { userLogin } from '../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'

const ResetPass = () => {

    const { token } = useParams()
    const redirect = useNavigate()
    const dispatch = useDispatch()
    const { isAuthenticated } = useSelector(state => state.Auth)
    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    })


    const dataTosend = {
        newPassword: data.password,
        resetToken: token
    }

    const showPassword = (field) => {
        const passwordField = document.querySelector(`input[name="${field}"]`);
        if (passwordField.type === 'password') {
            passwordField.type = 'text';
        } else {
            passwordField.type = 'password';
        }
    };


    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleClick = (e) => {
        e.preventDefault()
        if (data.password !== data.confirmPassword) {
            toast.error('Password does not match')
            return
        }
        axios.post(`${process.env.REACT_APP_API_HOST}/auth/reset-password`, dataTosend)
            .then(res => {
                toast.success(res.data.message)
                redirect('/login')
            })
            .catch(err => {
                toast.error(err.response.data.error)
            })
    }

    useEffect(() => {
        if (isAuthenticated) {
            redirect('/')
        }
        // eslint-disable-next-line
    }, [isAuthenticated])


    return (
        <>
            <div className="layout-page" style={{ alignSelf: 'center' }}>
                <div className="container-xxl" >
                    <div className="authentication-wrapper authentication-basic container-p-y">
                        <div className="authentication-inner d-flex justify-content-center" >
                            <div className="card" style={{ width: '35%' }}>
                                <div className="card-body">
                                    <h4 className="mb-2 text-center">Reset Password</h4>
                                    <p className="mb-4 text-center">Enter new password and verify to change your password</p>

                                    <form className="mb-3" onSubmit={handleClick}>
                                        <div className="mb-3 form-password-toggle">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label" htmlFor="password">Password</label>
                                            </div>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="••••••••••••"
                                                    aria-describedby="password"
                                                    onChange={handleChange}
                                                />
                                                <span
                                                    className="input-group-text cursor-pointer"
                                                    onClick={() => showPassword("password")}
                                                >
                                                    <i className="bx bx-hide"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="mb-3 form-password-toggle">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                            </div>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    className="form-control"
                                                    placeholder="••••••••••••"
                                                    aria-describedby="confirmPassword"
                                                    onChange={handleChange}
                                                />
                                                <span
                                                    className="input-group-text cursor-pointer"
                                                    onClick={() => showPassword("confirmPassword")}
                                                >
                                                    <i className="bx bx-hide"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary d-grid w-50 mt-3">Reset Password</button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPass