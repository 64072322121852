import { React } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';
import { toast } from 'react-hot-toast';

import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

const { deleteBrand, setRedirecting, setBrandStatus } = require('../actions/brandActions');
const { getProducts } = require('../actions/productActions');
const API_URL = process.env.REACT_APP_API_HOST

const BrandCategory = () => {
    const dispatch = useDispatch();
    const brands = useSelector(state => state.Brand.brandsCategory);
    const Loading = useSelector(state => state.Brand.loading);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteBrand(id));
            dispatch(getProducts());
        }
    }

    dispatch(setRedirecting());

    const hanldeStatus = (e, id) => {
        const status = e.target.value;
        axios.put(`${API_URL}/brand/status/${id}`, { status })
            .then(res => {
                dispatch(setBrandStatus(id, res.data));
                toast.success("Status Updated Successfully!");
            }
            ).catch(err => {
                toast.error("Something went wrong!");
            })
    }

    const selectOptions = [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'Inactive',
            value: 'inactive'
        }
    ]

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/brands/edit-brand-category/${row._id}`
                    }}><i className="bx bx-edit-alt me-1"></i> Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDelete(row.brandId) }} ><i className="bx bx-trash me-1"></i> Delete</div>
                </div>
            </div>,
            width: '100px',
        },
        {
            name: 'Status',
            selector: row => <select className='html-select' onChange={(e) => { hanldeStatus(e, row._id) }} >
                {selectOptions.map((option, index) => {
                    return <option key={index} selected={
                        option.value === row.status ? true : false
                    } value={option.value}>{option.label}</option>
                })}
            </select>,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Main Brand',
            selector: row => row.mainBrand?.name,
        },
    ];

    const data = brands.map(brand => {
        return {
            _id: brand._id,
            brandId: brand._id,
            id: brand.id,
            name: brand.name,
            mainBrand: brand.mainBrand,
            status: brand.status
        }
    }).sort((a, b) => a.priorityNumber - b.priorityNumber);

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    Loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-header">All Brand Categories</h5>
                                    <div>
                                        <Link to='/brands/add-brand-category' className="btn btn-outline-primary my-3">
                                            <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add Category
                                        </Link>

                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default BrandCategory