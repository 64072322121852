const brand = {
    brands: [],
    message: "",
    redirect: false,
    loading: false,
    error: null,
};

const BrandMain = (state = brand, action) => {
    switch (action.type) {
        case "GET_BRANDS_MAIN_REQUEST":
            return {
                ...state,
            };
        case "GET_BRANDS_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: action.brands,
            };
        case "GET_BRANDS_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_BRAND_MAIN_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_BRAND_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: [...state.brands, action.brand],
                redirect: true,
            };
        case "ADD_BRAND_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_BRAND_MAIN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_BRAND_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: state.brands.filter(
                    (brand) => brand._id !== action.id
                ),
            };
        case "DELETE_BRAND_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_BRAND_MAIN_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "UPDATE_BRAND_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: state.brands.map((brand) =>
                    brand.id === action.brand.id ? action.brand : brand
                ),
                redirect: true,
            };
        case "UPDATE_BRAND_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "GET_BRAND_MAIN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_BRAND_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                singleBrand: action.brand,
            };
        case "GET_BRAND_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
            };
        case "LOAD_BRANDS_MAIN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "LOAD_BRANDS_MAIN_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: action.brands,
            };
        case "LOAD_BRANDS_MAIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };
        case "UPDATE_BRAND_MAIN_STATUS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_BRAND_MAIN_STATUS_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: state.brands.map((brand) =>
                    brand._id === action.id ? action.brand : brand
                ),
            };
        case "UPDATE_BRAND_MAIN_STATUS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default BrandMain;
