import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-hot-toast';


const { addModel, updateModel } = require('../actions/modelActions');

const ModelForm = (props) => {
    const { id } = useParams();

    const { models } = useSelector(state => state.Models);

    const redirect = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { Loading } = useSelector(state => state.Category);
    const { brands } = useSelector(state => state.BrandMain);
    const { brandsCategory } = useSelector(state => state.Brand);

    const [mod, setMod] = useState({});
    const [model, setModel] = useState({
        name: "",
        brand: "",
        priorityNumber: "",
    });

    const [selctedBrand, setSelectedBrand] = useState(null);
    const [brandCatOptions, setBrandCatOptions] = useState([]);


    useEffect(() => {
        if (selctedBrand) {
            const brandCat = brandsCategory.filter((brand) => brand.mainBrand?._id === selctedBrand);
            setBrandCatOptions(
                brandCat.map((brand) => {
                    return { value: brand._id, label: brand.name };
                })
            );
        }
    }, [selctedBrand]);


    const selectOptions = brands.map((brand) => {
        return { value: brand._id, label: brand.name };
    });


    useEffect(() => {
        if (id) {
            const model = models.find((category) => category.id == id);

            if (model) {
                setMod(model);

                // Initialize the model's state
                setModel({
                    name: model.name,
                    brand: model.brand?._id,
                    priorityNumber: model.priorityNumber,
                });

                // Set selected brand for the primary select
                setSelectedBrand(model.brand?.mainBrand?._id);

                // Update brand category options
                const brandCat = brandsCategory.filter(
                    (brand) => brand.mainBrand?._id === model.brand?.mainBrand?._id
                );

                setBrandCatOptions(
                    brandCat.map((brand) => ({
                        value: brand._id,
                        label: brand.name,
                    }))
                );
            }
        }
        else {
            setModel({
                name: "",
                brand: "",
            });
            setSelectedBrand(null);
            setBrandCatOptions([]);
        }
    }, [id, models, brandsCategory]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModel({ ...model, [name]: value });
    };

    const handleAddSubCategory = (e) => {
        e.preventDefault();

        if (model.name === "") {
            toast.error("Please enter model name");
            return;
        } else if (model.brand === "") {
            toast.error("Please select a brand category");
            return;
        } else {
            dispatch(addModel(model)); // Add model to the store
            redirect("/models"); // Redirect after adding
        }
    };


    const handleUpdateSubCategory = (e) => {
        e.preventDefault();
        dispatch(updateModel(mod._id, model));
        redirect('/models');
    }

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    Loading ? (<PulseLoader />) : (
                        <div className="row p-4 justify-content-center">
                            <div className="col-md-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form  >
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label required"
                                                            htmlFor="basic-default-fullname"
                                                        >
                                                            Brand
                                                        </label>
                                                        <Select
                                                            options={selectOptions}
                                                            onChange={(e) => {
                                                                setSelectedBrand(e.value);

                                                                // Update model with the selected main brand
                                                                const brandCat = brandsCategory.filter((brand) => brand.mainBrand?._id === e.value);
                                                                setBrandCatOptions(
                                                                    brandCat.map((brand) => ({ value: brand._id, label: brand.name }))
                                                                );

                                                                setModel({ ...model, brand: '' }); // Reset the brand field
                                                            }}
                                                            value={selectOptions.find((obj) => obj.value === selctedBrand)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label required"
                                                            htmlFor="basic-default-fullname"
                                                        >
                                                            Brand Category
                                                        </label>
                                                        <Select
                                                            options={brandCatOptions}
                                                            onChange={(e) => {
                                                                handleInputChange({ target: { name: 'brand', value: e.value } });
                                                            }}
                                                            value={brandCatOptions.find((obj) => obj.value === model.brand)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="Model Name" name='name' value={model.name} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                                                    </div>
                                                    <div className="mb-3">
                                                        <InputField label="Priority Number" name='priorityNumber' value={model.priorityNumber} onChange={handleInputChange} required={true} type='text' placeholder="Priority number here..." />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateSubCategory} className="btn btn-primary">
                                                    Update Model
                                                </button> : <button type="submit" onClick={handleAddSubCategory} className="btn btn-primary">
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default ModelForm