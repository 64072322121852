import { React, useState, useEffect } from 'react'
import { Link, } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import Papa from 'papaparse';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const { deleteProduct } = require('../actions/productActions');


const Product = () => {
    const [csvData, setcsvData] = useState([]);
    const [exportReady, setExportReady] = useState(false);
    const csvDownload = () => {
        const csv = Papa?.unparse(csvData);
        const element = document.createElement("a");
        const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        element.href = URL.createObjectURL(file);
        element.download = "products.csv";
        document.body.appendChild(element);
        element.click();
    }
    const createCsvData = (productsData) => {
        const csv = productsData?.map(product => {
            return {
                Id: product?.id,
                Name: product?.name,
                Category: product.category ? product.category.name : 'Uncategorized',
                "Sub Category": product?.subcategory?.name,
                SKU: product?.skuNumber,
                Barcode: product?.barCode,
                Stock: product?.stock,
                Bestseller: product?.bestSeller,
                Clearance: product?.clearance,
                "New Arrival": product?.newArrival,
                "Consumer Price": product?.price,
                "Consumer Discounted Price": product?.consumerDiscount,
                "Retailer Price": product?.retailerPrice,
                "Retailer Discounted Price": product?.retailerDiscount,
                "Dropshipper Price": product?.dropshipperPrice,
                "Dropshipper Discounted Price": product?.dropshipperDiscount,
                Brand: product.brand ? product.brand.name : "All",
                Model: product.model ? product.model.name : "All",
                Design: product.design ? product.design.name : "All",
                Carrier: product.carrier ? product.carrier.name : "All",
                Height: product?.height,
                Width: product?.width,
                Length: product?.length,
                Weight: product?.weight,
                Color: product?.color,
                Status: product?.status,
                // Photos: product?.images?.map(photo => {
                //     return photo
                // }).join('|'),
                UrlImages: product?.images?.map(url => {
                    return url;
                }).join('|'),
                Description: product.description
            }
        });
        setcsvData(csv);

    }
    const dispatch = useDispatch();
    const productsData = useSelector(state => state.Product.products);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(() => {
        return Number(localStorage.getItem('page')) || 1;
    });
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(() => {
        return Number(localStorage.getItem('perPage')) || 10;
    });
    const [query, setQuery] = useState("");
    const fetchProducts = async (page, perPage, query) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/products/adminproducts`, {
                params: {
                    page,
                    limit: perPage,
                    query
                },
            });
            const { products, totalProducts } = response.data;
            const filterProducts = products?.map(product => {
                return {
                    prodId: product._id,
                    id: product.id,
                    name: product.name,
                    category: product.category ? product.category.name : 'Uncategorized',
                    status: product.status,
                    price: product.price,
                    stock: product.stock,
                    barcode: product.barCode,
                }
            });

            setProducts(filterProducts);
            setTotalRows(totalProducts);
        } catch (error) {
            console.error('Failed to fetch products', error);
        } finally {
            setLoading(false);
        }
    };
    // const [csvData, setcsvData] = useState([]);

    // Handle page change for pagination
    const handlePageChange = (page) => {
        setPage(page);
        localStorage.setItem('page', page);
    };

    // Handle rows per page change
    const handlePerRowsChange = async (newPerPage) => {

        setPerPage(newPerPage);
        localStorage.setItem('perPage', newPerPage);
    };
    // Fetch products on component mount and when page or perPage changes
    useEffect(() => {
        fetchProducts(page, perPage, query);
    }, [page, perPage]);

    useEffect(() => {
        if (productsData.length > 0) {
            setExportReady(true);
            createCsvData(productsData);
        }
    }, [productsData]);
    const handleChange = (e) => {
        setQuery(e.target.value);

    }
    const hanlderSearchClick = () => {
        fetchProducts(page, perPage, query)
    }
    const hanlderResetClick = () => {
        setQuery("")
        fetchProducts(page, perPage, "")

    }

    const handleDeleteProduct = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteProduct(id));
        }
    }


    const selectOptions = [
        { value: 'Draft', label: 'Draft' },
        { value: 'Published', label: 'Published' },
        { value: 'Unpublished', label: 'Unpublished' }
    ]

    const hanldeStatus = (e, id) => {
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API_HOST}/products/update-status/${id}`, { status: e.target.value }, {

            headers: {
                "x-auth-token": `${localStorage.getItem("xat")}`,
            },
        }).then(res => {
            toast.success('Product status updated!');

        }).catch(err => {
            toast.error('Something went wrong!');
        }
        )
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/products/edit-product/${row.id}`
                    }}><i className="bx bx-edit-alt me-1"></i>Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDeleteProduct(row.prodId) }} ><i className="bx bx-trash me-1"></i>Delete</div>
                </div>
            </div>,
            width: '100px'
        },
        {
            name: 'Bar Code',
            selector: row => row.barcode,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Status',
            selector: row => <select onChange={(e) => { hanldeStatus(e, row.prodId) }} >
                {selectOptions.map((option, index) => {
                    return <option key={index} selected={
                        option.value === row.status ? true : false
                    } value={option.value}>{option.label}</option>
                })}
            </select>,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            sortable: true,
            width: '100px'
        },

    ];


    return (
        <>
            <div className="content-wrapper container">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <h5 className="card-header">All Products</h5>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                name="search"
                                                onChange={handleChange}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        hanlderSearchClick(); // Call your search function or perform the action
                                                    }
                                                }}
                                                value={query}
                                                className="form-control my-3 me-2"
                                                placeholder="Search By Product ID or Barcode"
                                            />
                                            <button className="btn btn-outline-primary" onClick={hanlderSearchClick}>
                                                Search
                                            </button>
                                            <button className="btn btn-outline-primary" onClick={hanlderResetClick}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-end d-flex justify-content-end ">
                                        <div className='mx-2'>
                                            <button className="btn btn-outline-primary my-3" disabled={!exportReady} onClick={csvDownload}>
                                                <span className="tf-icons bx bxs-file-export"></span>&nbsp; Export
                                            </button>
                                        </div>
                                        <div className='mx-2'>
                                            <Link to='/products/import-product' className="btn btn-outline-primary my-3">
                                                <span className="tf-icons bx bxs-file-import"></span>&nbsp; Import
                                            </Link>
                                        </div>
                                        <div className='mx-2'>
                                            <Link to='/products/add-product' className="btn btn-outline-primary my-3">
                                                <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable
                                        title="Products"
                                        columns={columns}
                                        data={products}
                                        progressPending={loading}
                                        paginationPerPage={perPage}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={page}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100, 200]}
                                        highlightOnHover
                                        striped
                                    />
                                </div>
                            </div>
                        </div>

                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default Product