import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { updateOrderStatus, updateRetailerOrderStatus, updateRetailerPaymentStatus } from "../../actions/orderActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import RetailerOrderSummary from "./RetailerOrderSummary";
import ShippingDetails from './ShippingDetails';
const RetailerOrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.Order.retailer_orders);
  const Loading = useSelector((state) => state.Order.loading);
  let order = orders.find((item) => item._id === id);
  const [orderStatus, setOrderStatus] = useState(order?.status);
  const [paymentStatus, setPaymentStatus] = useState(order?.isPaid);
  const [currentTab, setCurrentTab] = useState("order");
  const handleTab = (tab) => {
    setCurrentTab(tab);
  };
  const [orderStatusOptions, setOrderStatusOptions] = useState([
    { value: "Received", label: "Received" },
    { value: "Processing", label: "Processing" },
    { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Completed", label: "Completed" },
  ])

  useEffect(() => {
    if (order.customer?.payAtStore) {
      setOrderStatusOptions([
        { value: "Received", label: "Received" },
        { value: "Processing", label: "Processing" },
        { value: "Shipped", label: "Shipped" },
        { value: "Delivered", label: "Delivered" },
        { value: "Cancelled", label: "Cancelled" },
        { value: "Completed", label: "Completed" },
        { value: "Awaiting Payment at Store", label: "Awaiting Payment at Store" },
      ])
    }
  }, []); //  eslint-disable-line


  const paymentStatusOptions = [
    { value: true, label: "Paid" },
    { value: false, label: "Pending" }
  ];

  const handlepaymentStatusOptions = (e) => {
    setPaymentStatus(e.value);
    dispatch(updateRetailerPaymentStatus(order._id, e.value));

  };


  const handleorderStatusOptions = (e) => {
    setOrderStatus(e.value);
    dispatch(updateRetailerOrderStatus(order._id, e.value));
  };

  const updateOrderStatusDispatch = (status) => {
    dispatch(updateOrderStatus(order._id, status));
  };

  useEffect(() => {
    setOrderStatus(order.status);
  }, [order.status]);


  return (Loading ? <p>Loading ..</p> :

    <>
      <div className="content-wrapper container-fluid">
        <h2 className="card-title mb-3">Order Management</h2>
        <div className="card mb-3">
          <ul className="tab-list">
            <li className={currentTab === "order" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("order") }}>
              Order Items
            </li>
            <li className={currentTab === "shipping" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("shipping") }}>
              Shipping Info
            </li>
            <li className={currentTab === "billing" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("billing") }}>
              Billing Info
            </li>
            <li className={currentTab === "payment" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("payment") }}>
              Payment Info
            </li>
            <li className={currentTab === "customer" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("customer") }}>Customer Info</li>
            <li className={currentTab === "invoice" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("invoice") }}>
              Invoice
            </li>
            <li className={currentTab === "action" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("action") }}>Actions</li>
          </ul>
        </div>
        {
          currentTab === "order" && (
            <RetailerOrderSummary data={order} />
          )
        }
        {
          currentTab === "shipping" && (
            <ShippingDetails order={order} updateOrderStatusDispatch={updateOrderStatusDispatch} />
          )
        }
        {
          currentTab === "billing" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-body">
                      <p>
                        <b>Name : </b> {order.billingInfo.name}
                      </p>
                      <p>
                        <b>Email : </b> {order.billingInfo.email}
                      </p>
                      <p>
                        <b>Phone : </b> {order.billingInfo.phone}
                      </p>
                      <p>
                        <b>City : </b> {order.billingInfo.city}
                      </p>
                      <p>
                        <b>Country : </b> {order.billingInfo.country}
                      </p>
                      <p>
                        <b>Zip Code : </b> {order.billingInfo.postalCode}
                      </p>
                      <p>
                        <b>Address : </b> {order.shippingInfo.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          currentTab === "payment" && (
            <>
              <div className="col-md-4">

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          <b>Payment Method : </b> {(order.customer?.payAtStore === false && order.paymentIntentId !== '') ? "Credit Card" : "Pay at Store"}
                        </p>
                        <p>
                          <b> Order Total: </b> {order.customer?.payAtStore === true ? order.grandTotal : order.totalPrice}
                        </p>
                        <p>
                          <b>Shipping Price : </b> {order.shippingCharges}
                        </p>
                        <p>
                          <b>Grand Total : </b> {order.grandTotal}
                        </p>
                        <p>
                          <b>Payment Status : </b>
                          {order.isPaid === true ? "Paid" : "Pending"}
                        </p>
                        <p>
                          <b>Order Status : </b>
                          {orderStatus}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </>
          )
        }
        {
          currentTab === "action" && (
            <>
              <div className="row">
                {
                  order.customer?.payAtStore === true && (<div className="col-md-3">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-label">Payment Status (Pay At Store)</label>
                          {/* <Select options={orderStatusOptions}
                            value={
                              orderStatusOptions.find((option) => option.value === order.status)
                            }
                          /> */}
                          <Select
                            options={paymentStatusOptions}
                            onChange={handlepaymentStatusOptions}
                            value={
                              paymentStatus === null
                                ? ""
                                : paymentStatusOptions.find((option) => {
                                  return (
                                    option.value === paymentStatus
                                  );
                                })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>)
                }

                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-label">Order Status</label>
                        {/* <Select options={orderStatusOptions}
                          value={
                            orderStatusOptions.find((option) => option.value === order.status)
                          }
                        /> */}
                        <Select
                          options={orderStatusOptions}
                          onChange={handleorderStatusOptions}
                          value={
                            orderStatus === null
                              ? ""
                              : orderStatusOptions.find((option) => {
                                return (
                                  option.value === orderStatus
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          currentTab === "customer" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-body">
                      {
                        order.customer ? (<>
                          <p>
                            <b>Name : </b> <Link to={`/customers/approve-cusotmer/${order.customer?._id}`}>{order.customer?.firstName} {order.customer?.lastName}</Link>
                          </p>
                          <p>
                            <b>Email : </b> {order.customer?.email}
                          </p>
                          <p>
                            <b>Customer Type : </b> {order.customer?.type}
                          </p>
                          <p>
                            <b>Phone : </b> {order.customer?.telBusiness}
                          </p>
                        </>
                        ) : (
                          <>
                            <p className="text-center">
                              No Customer Info
                            </p>
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>
              </div>
            </>
          )

        }

      </div>
    </>
  );
};

export default RetailerOrderDetails;
