import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getMainBrandMains = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_BRANDS_MAIN_REQUEST" });
            const { data } = await axios.get(`${API_URL}/main-brand/admin`);
            dispatch({ type: "GET_BRANDS_MAIN_SUCCESS", brands: data });
        } catch (error) {
            dispatch({ type: "GET_BRANDS_MAIN_FAILURE", error: error.message });

        }
    };
};

export const addBrandMain = (brand) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_BRAND_MAIN_REQUEST" });
            const { data } = await axios.post(`${API_URL}/main-brand`, brand, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_BRAND_MAIN_SUCCESS",
                brand: data.createdBrandMainMain,
            });
            toast.success('BrandMain added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_BRAND_MAIN_FAILURE", error: error.message });
            toast.error('BrandMain addition failed!');
        }
    };
};

export const deleteBrandMain = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_BRAND_MAIN_REQUEST" });
            await axios.delete(`${API_URL}/main-brand/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_BRAND_MAIN_SUCCESS", id });
            toast.success('BrandMain deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_BRAND_MAIN_FAILURE", error: error.message });
            toast.error('BrandMain deletion failed!');
        }
    };
};

export const updateBrandMain = (id, brand) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_BRAND_MAIN_REQUEST" });
            const { data } = await axios.put(`${API_URL}/main-brand/${id}`, brand, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({ type: "UPDATE_BRAND_MAIN_SUCCESS", brand: data });
            toast.success('BrandMain updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_BRAND_MAIN_FAILURE", error: error.message });
            toast.error('BrandMain update failed!');
        }
    };
};

export const loadMainBrandMains = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "LOAD_BRANDS_MAIN_REQUEST" });
            dispatch({ type: "LOAD_BRANDS_MAIN_SUCCESS", brands: data });
        } catch (error) {
            dispatch({ type: "LOAD_BRANDS_MAIN_FAILURE", error: error.message });
        }
    };
};


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}


export const setBrandMainStatus = (id, brand) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_BRAND_MAIN_STATUS_REQUEST" });
            dispatch({ type: "UPDATE_BRAND_MAIN_STATUS_SUCCESS", id, brand });
        } catch (error) {
            dispatch({ type: "UPDATE_BRAND_MAIN_STATUS_FAILURE", error: error.message });
        }
    };
}