import { React, useState } from 'react'
import { userLogin } from '../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {

  const redirect = useNavigate()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.Auth)
  const [data, setData] = useState({
    email: '',
    password: ''
  })

  const showPassword = () => {
    const password = document.querySelector('input[name="password"]')
    if (password.type === 'password') {
      password.type = 'text'
    } else {
      password.type = 'password'
    }
  }


  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleClick = (e) => {
    e.preventDefault()
    dispatch(userLogin(data))
  }

  useEffect(() => {
    if (isAuthenticated) {
      redirect('/')
    }
    // eslint-disable-next-line
  }, [isAuthenticated])


  return (
    <>
      <div className="layout-page" style={{ alignSelf: 'center' }}>
        <div className="container-xxl" >
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner d-flex justify-content-center" >
              <div className="card" style={{ width: '35%' }}>
                <div className="card-body">
                  <h4 className="mb-2 text-center">Welcome to Admin 👋</h4>
                  <p className="mb-4 text-center">Please sign-in to your account and start the adventure</p>

                  <form className="mb-3" onSubmit={handleClick}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email or Username</label>
                      <input
                        type="email"
                        required
                        className="form-control"
                        name="email"
                        placeholder="Enter your email or username"
                        autoFocus
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">Password</label>
                        <Link to="/forgot-password">
                          <small>Forgot Password?</small>
                        </Link>
                      </div>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                          onChange={handleChange}
                        />
                        <span className="input-group-text cursor-pointer" onClick={showPassword}><i className="bx bx-hide"></i></span>
                      </div>
                    </div>

                    <div className=" d-flex justify-content-center">
                      <button type='submit' className="btn btn-primary d-grid w-50 mt-3">Sign in</button>
                    </div>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login