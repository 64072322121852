const brand = {
    brandsCategory: [],
    message: "",
    redirect: false,
    loading: false,
    error: null,
};

const Brand = (state = brand, action) => {
    switch (action.type) {
        case "GET_BRANDS_REQUEST":
            return {
                ...state,
            };
        case "GET_BRANDS_SUCCESS":
            return {
                ...state,
                loading: false,
                brandsCategory: action.brands,
            };
        case "GET_BRANDS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_BRAND_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_BRAND_SUCCESS":
            return {
                ...state,
                loading: false,
                brandsCategory: [...state.brandsCategory, action.brand],
                redirect: true,
            };
        case "ADD_BRAND_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_BRAND_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_BRAND_SUCCESS":
            return {
                ...state,
                loading: false,
                brandsCategory: state.brandsCategory.filter(
                    (brand) => brand._id !== action.id
                ),
            };
        case "DELETE_BRAND_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_BRAND_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "UPDATE_BRAND_SUCCESS":
            return {
                ...state,
                loading: false,
                brands: state.brandsCategory.map((brand) =>
                    brand.id === action.brand.id ? action.brand : brand
                ),
                redirect: true,
            };
        case "UPDATE_BRAND_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "GET_BRAND_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_BRAND_SUCCESS":
            return {
                ...state,
                loading: false,
                singleBrand: action.brand,
            };
        case "GET_BRAND_FAILURE":
            return {
                ...state,
                loading: false,
            };
        case "LOAD_BRANDS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "LOAD_BRANDS_SUCCESS":
            return {
                ...state,
                loading: false,
                brandsCategory: action.brands,
            };
        case "LOAD_BRANDS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };
        case "UPDATE_BRAND_STATUS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_BRAND_STATUS_SUCCESS":
            return {
                ...state,
                loading: false,
                brandsCategory: state.brandsCategory.map((brand) =>
                    brand._id === action.id ? action.brand : brand
                ),
            };
        case "UPDATE_BRAND_STATUS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default Brand;
