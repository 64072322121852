import React, { useState, useEffect } from 'react';
import './MultiImage.css';
import axios from 'axios';
import PulseLoader from '../PulseLoader';
import { toast } from 'react-hot-toast';
import InputField from "../InputFields/InputField";

const MultipleImageFieldForEdit = ({ name,fileImages,urlImages, size,productId}) => {
    const [prevImages, setPrevImages] = useState([]);
    const [urlFiles,setUrlFiles]=useState([]);
    const [url,setUrl]=useState("");
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_API_HOST;
    const addUrl=()=>{
        if(url.length===0){
            toast.error('Please provide a valid Image Url');
            return;
        }
        const data={
            productId,
            urlImage:url,
        }
        setLoading(true);
        axios.post(`${host}/file/upload-url-image`, data, {
            headers: {
                'x-auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            let urls=[...urlFiles,url];
            setUrlFiles(urls);
            setUrl("");
            setLoading(false);
            toast.success('Files uploaded successfully');
        }
        ).catch(err => {
            setLoading(false);
            toast.error('Files upload failed');
        })
       
    }
    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        setLoading(true);
        const data = new FormData();
        data.append('productId', productId); 

        for (let i = 0; i < files.length; i++) {
            data.append('photos', files[i])
        }
        axios.post(`${host}/file/upload`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            setPrevImages((prevImages) => [...prevImages, ...res.data]);
            setLoading(false);
            toast.success('Files uploaded successfully');
        }
        ).catch(err => {
            setLoading(false);
            toast.error('Files upload failed-1');
        })
    };

    const handleRemoveImage = (image, i) => {
        const updatedImages = [...prevImages];
        const imageUrl=encodeURIComponent(image);

        axios.post(`${host}/file/delete/${productId}/${imageUrl}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            updatedImages.splice(i, 1);
            setPrevImages(updatedImages);
            toast.success('File deleted successfully');
        }
        ).catch(err => {
            toast.error('File deletion failed');
        })
    };
    const handleRemoveUrlImage = (image, i) => {
        const updatedImages = [...urlFiles];
        const imageUrl=encodeURIComponent(image);
         
        axios.post(`${host}/file/url-delete/${productId}/${imageUrl}`,{productId}, {
            headers: {
                'x-auth-token': localStorage.getItem('token')
            }
        }).then(res => {
            updatedImages.splice(i, 1);
            setUrlFiles(updatedImages);
            toast.success('File deleted successfully');
        }
        ).catch(err => {
            toast.error('File deletion failed');
        })
    };

    useEffect(() => {
        if (fileImages) {
            setPrevImages(fileImages);
        }
        // eslint-disable-next-line
    }, [fileImages]);

    useEffect(() => {
        setUrlFiles(urlImages);
        // eslint-disable-next-line
    }, [urlImages]);


    return (
        <div className=''>
            <div className="mb-3">
                <label className='form-label'> Upload Images ({size})</label>
                <div className='image-preview'>
                {prevImages.map((image, index) => (

                <div key={index} className="image-preview-item">
                        {image.split('.').pop() !== 'mp4' ? (
                            <img src={image} alt={`Product ${index + 1}`} />
                        ) : (
                            <video src={image} alt={`Video ${index + 1}`} autoPlay loop />
                        )}
                        <i className="bx bx-x" onClick={() => handleRemoveImage(image, index)}></i>
                    </div>
                    ))}
                </div>
                <input className="form-control drop-area" name={name} onChange={handleFileChange} type="file" accept="image/*, video/*" multiple />
            </div>
            <div className="image-preview">
                {loading ? <div className='loaderImage'> <PulseLoader /> </div> :
                    <>
                        {/* {prevImages.map((image, index) => (

                            <div key={index} className="image-preview-item">
                                {image.split('.').pop() !== 'mp4' ? (
                                    <img src={image} alt={`Product ${index + 1}`} />
                                ) : (
                                    <video src={image} alt={`Video ${index + 1}`} autoPlay loop />
                                )}
                                <i className="bx bx-x" onClick={() => handleRemoveImage(image, index)}></i>
                            </div>
                        ))} */}
                        {urlFiles.map((image, index) => (

                            <div key={index} className="image-preview-item">
                                {image.split('.').pop() !== 'mp4' ? (
                                    <img src={image} alt={`Product ${index + 1}`} />
                                ) : (
                                    <video src={image} alt={`Video ${index + 1}`} autoPlay loop />
                                )}
                                <i className="bx bx-x" onClick={() => handleRemoveUrlImage(image, index)}></i>
                            </div>
                        ))}
                    </>
                }
            </div>
            <div className="row">
                    <h3 className="text-center">Or</h3>
            </div>
            <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3">
                        <InputField
                          label="Image URL"
                          name="imgURL"
                          value={url}
                          onChange={(e) => { setUrl(e.target.value) }}
                          type="text"
                          placeholder="Product Image URL"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 mt-1">
                      <div className="mt-4">
                        <button className="btn btn-outline-primary" onClick={addUrl}>
                          Add URL
                        </button>
                      </div>
                    </div>
            </div>
        </div>
    );
};

export default MultipleImageFieldForEdit;