import { React, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import './Sidebar.css'
import { useDispatch, useSelector } from 'react-redux';
import { getDesigns } from '../../actions/designActions';
import { getTaxes } from '../../actions/taxActions';
import { getADs } from '../../actions/adsAction';
import Session from '../../utils/Session';
import { getMainBrandMains } from '../../actions/brandMainActions';
const { getCategories } = require('../../actions/categoryActions');
const { getProducts } = require('../../actions/productActions');
const { getAllOrders, getAllRetailerOrders } = require('../../actions/orderActions');
const { userGetAll, userLogout, getAdminUser } = require('../../actions/authActions');
const { getTiers } = require('../../actions/tierActions');
const { getSubCategories } = require('../../actions/subCategoryActions');
const { getTags } = require('../../actions/tagActions');
const { getDropshipFee } = require('../../actions/dropshipFeeAction');
const { getBrands } = require('../../actions/brandActions');
const { getCarriers } = require('../../actions/carrierActions');
const { getBanners } = require('../../actions/bannerActions');
const { getModels } = require('../../actions/modelActions');

const Sidebar = () => {
    const dispatch = useDispatch();
    const arrow = useRef([]);
    const [subMenuIndex, setSubMenuIndex] = useState(-1);
    const { isAuthenticated } = useSelector(state => state.Auth);


    const handleMenuClick = (index) => {
        if (subMenuIndex === index) {
            setSubMenuIndex(-1);
        } else {
            setSubMenuIndex(index);
        }
    };


    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getCategories());
            dispatch(getProducts());
            dispatch(getAllOrders());
            dispatch(getAllRetailerOrders());
            dispatch(userGetAll());
            dispatch(getTiers());
            dispatch(getSubCategories());
            dispatch(getTags());
            dispatch(getDropshipFee());
            dispatch(getBrands());
            dispatch(getCarriers());
            dispatch(getBanners());
            dispatch(getModels());
            dispatch(getDesigns());
            dispatch(getTaxes());
            dispatch(getADs());
            dispatch(getAdminUser());
            dispatch(getMainBrandMains());
        }

    }, [isAuthenticated])// eslint-disable-line react-hooks/exhaustive-deps


    const currentUrl = window.location.pathname;
    return (
        <>
            <Session />
            <Navbar />
            <div className="nav" id="navbar">
                <nav className="nav__container">
                    <div>
                        <Link to="/" className="nav__link nav__logo">
                            <i className='bx bxs-disc nav__icon' ></i>
                            <span className="nav__logo-name">Remax</span>
                        </Link>

                        <div className="nav__list">
                            <div className="nav__items">
                                <h3 className="nav__subtitle">Profile</h3>

                                <Link to="/" className={
                                    currentUrl === '/' ? 'nav__link active' : 'nav__link'
                                }>
                                    <i className='bx bx-home nav__icon' ></i>
                                    <span className="nav__name">Dashboard</span>
                                </Link>

                                <div className={
                                    subMenuIndex === 2 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[2] = el)}
                                    onClick={() => handleMenuClick(2)}>
                                    <div className="nav__link">
                                        <i className='bx bx-user nav__icon' ></i>
                                        <span className="nav__name">Customer</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/customers" className="nav__dropdown-item">All Customers</Link>
                                            <Link to="/customer/pending-requests" className="nav__dropdown-item">Pending Customers</Link>
                                            <Link to="/customer/rejected-requests" className="nav__dropdown-item">Rejected Customers</Link>
                                            <Link to="/customer/blocked-requests" className="nav__dropdown-item">Blocked Customers</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className={
                                    subMenuIndex === 1 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[1] = el)}
                                    onClick={() => handleMenuClick(1)}>
                                    <div className="nav__link">
                                        <i className='bx bx-category nav__icon' ></i>
                                        <span className="nav__name">Category</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/categories" className="nav__dropdown-item">Categories</Link>
                                            <Link to="/sub-categories" className="nav__dropdown-item">Sub Categories</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={
                                    subMenuIndex === 3 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[3] = el)}
                                    onClick={() => handleMenuClick(3)}>
                                    <div className="nav__link">
                                        <i className='bx bx-store-alt nav__icon' ></i>
                                        <span className="nav__name">Products</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/products" className="nav__dropdown-item">All Products</Link>
                                            <Link to="/products/draft" className="nav__dropdown-item">Drafts</Link>
                                            <Link to="/products/publish" className="nav__dropdown-item">Publish</Link>
                                            <Link to="/products/unpublish" className="nav__dropdown-item">Unpublish</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={
                                    subMenuIndex === 5 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[5] = el)}
                                    onClick={() => handleMenuClick(5)}>
                                    <div className="nav__link">
                                        <i className='bx bx-file nav__icon' ></i>
                                        <span className="nav__name">Reports</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/sale-report" className="nav__dropdown-item">Sale Report</Link>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/tier" className={
                                    currentUrl === '/tier' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-trending-up nav__icon' ></i>
                                    <span className="nav__name">Discount Tier</span>
                                </Link>

                                {/* <Link to="/tag" className={
                                    currentUrl === '/tag' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-tag nav__icon' ></i>
                                    <span className="nav__name">Tag</span>
                                </Link> */}

                                <div className={
                                    subMenuIndex === 10 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[10] = el)}
                                    onClick={() => handleMenuClick(10)}>
                                    <div className="nav__link">
                                        <i className='bx bx-bold nav__icon' ></i>
                                        <span className="nav__name">Brands</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/brands" className="nav__dropdown-item">Brands</Link>
                                            <Link to="/brands/category" className="nav__dropdown-item">Brands Category</Link>
                                        </div>
                                    </div>
                                </div>

                                <Link to="/models" className={
                                    currentUrl === '/models' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxs-devices nav__icon'></i>
                                    <span className="nav__name">Models </span>
                                </Link>

                                <Link to="/designs" className={
                                    currentUrl === '/designs' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxl-sketch nav__icon'></i>
                                    <span className="nav__name">Designs </span>
                                </Link>

                                <Link to="/carriers" className={
                                    currentUrl === '/carriers' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bx-mobile-alt nav__icon'></i>
                                    <span className="nav__name">Carriers </span>
                                </Link>


                                {/* <Link to="/orders" className={
                                    currentUrl === '/orders' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxs-purchase-tag-alt nav__icon' ></i>
                                    <span className="nav__name">Orders </span>
                                </Link> */}
                                <Link to="/retailer-orders" className={
                                    currentUrl === '/retailer-orders' ? 'nav__link active' : 'nav__link'
                                } >
                                    <i className='bx bxs-purchase-tag-alt nav__icon' ></i>
                                    <span className="nav__name">Retailer Orders </span>
                                </Link>

                                <div className={
                                    subMenuIndex === 4 ? 'nav__dropdown dropdown_active' : 'nav__dropdown'
                                } ref={(el) => (arrow.current[4] = el)}
                                    onClick={() => handleMenuClick(4)}>
                                    <div className="nav__link">
                                        <i className='bx bx-cog nav__icon' ></i>
                                        <span className="nav__name">Settings</span>
                                        <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                    </div>

                                    <div className="nav__dropdown-collapse">
                                        <div className="nav__dropdown-content">
                                            <Link to="/banners" className="nav__dropdown-item">Banners</Link>
                                            <Link to="/ads-spots" className="nav__dropdown-item">Ads Spots</Link>
                                            <Link to="/tax" className="nav__dropdown-item">State Tax</Link>
                                            <Link to="/dropshipfee" className="nav__dropdown-item">Dropship Fee</Link>
                                            <Link to="/admin-user" className="nav__dropdown-item">Admin Users</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="nav__link nav__logout" onClick={() => dispatch(userLogout())} >
                        <i className='bx bx-log-out nav__icon' ></i>
                        <span className="nav__name">Log Out</span>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Sidebar