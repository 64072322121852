import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { updateOrderStatus } from "../actions/orderActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const OrderDeatil = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.Order.orders);
  const order = orders.find((order) => order._id === id);
  const [orderStatus, setOrderStatus] = useState(order.status);
  const [currentTab, setCurrentTab] = useState("order");
  const handleTab = (tab) => {
    setCurrentTab(tab);
  };

  const orderStatusOptions = [
    { value: "Received", label: "Received" },
    { value: "Processing", label: "Processing" },
    { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Completed", label: "Completed" }
  ];

  const handleorderStatusOptions = (e) => {
    setOrderStatus(e.value);
    dispatch(updateOrderStatus(order._id, e.value));
  };

  const updateOrderStatusDispatch = (status) => {
    dispatch(updateOrderStatus(order._id, status));
  };

  useEffect(() => {
    setOrderStatus(order.status);
  }, [order.status]);


  return (
    <>
      <div className="content-wrapper container-fluid">
        <h2 className="card-title mb-3">Order Management</h2>
        <div className="card mb-3">
          <ul className="tab-list">
            <li className={currentTab === "order" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("order") }}>
              Order Items
            </li>
            <li className={currentTab === "shipping" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("shipping") }}>
              Shipping Info
            </li>
            <li className={currentTab === "billing" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("billing") }}>
              Billing Info
            </li>
            <li className={currentTab === "payment" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("payment") }}>
              Payment Info
            </li>
            <li className={currentTab === "customer" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("customer") }}>Customer Info</li>
            <li className={currentTab === "invoice" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("invoice") }}>
              Invoice
            </li>
            <li className={currentTab === "action" ? "tab-item active" : "tab-item"} onClick={() => { handleTab("action") }}>Actions</li>
          </ul>
        </div>
        {
          currentTab === "order" && (
            <div className="card mb-3">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.orderItems.map((item) => {
                      return (
                        // <Link to={`/products/edit-product/${item._id}`}>
                        <tr key={item._id}>
                          <td>
                            <img
                              src={item.image}
                              alt={item.name}
                              style={{ width: "100px", height: "100px" }}
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                          <td>{item.price}</td>
                        </tr>
                        // </Link>
                      );
                    }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )
        }
        {
          currentTab === "shipping" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-body">
                      <p>
                        <b>Name : </b> {order.shippingInfo.name}
                      </p>
                      <p>
                        <b>Email : </b> {order.shippingInfo.email}
                      </p>
                      <p>
                        <b>Phone : </b> {order.shippingInfo.phone}
                      </p>
                      <p>
                        <b>City : </b> {order.shippingInfo.city}
                      </p>
                      <p>
                        <b>Country : </b> {order.shippingInfo.country}
                      </p>
                      <p>
                        <b>Zip Code : </b> {order.shippingInfo.postalCode}
                      </p>
                      <p>
                        <b>Address : </b> {order.shippingInfo.address}
                      </p>
                      <p>
                        <b>Order Note : </b> {order.orderNote}
                      </p>
                      <p>

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mb-3">
                    <h4 className="card-header">Shipping Actions</h4>
                    <div className="card-body">
                      <div className="d-flex gap-3">
                        <button className="btn btn-outline-success" >Create Shipping Label</button>
                        <button className={order.status === "Delivered" ? "d-none" : "btn btn-outline-primary"} onClick={() => updateOrderStatusDispatch("Shipped")}>
                          {order.status !== "Shipped" ? ("Mark as Shipped") : (order.status === "Cancelled" ? orderStatus : "Shipped")}
                        </button>
                        <button className={order.status === "Delivered" ? "d-none" : "btn btn-outline-danger"} onClick={() => updateOrderStatusDispatch("Cancelled")} disabled={order.status === "Delivered" ? true : false}>
                          {order.status !== "Cancelled" ? ("Cancel Order") : (" Cancelled")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          currentTab === "billing" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-body">
                      <p>
                        <b>Name : </b> {order.billingInfo.name}
                      </p>
                      <p>
                        <b>Email : </b> {order.billingInfo.email}
                      </p>
                      <p>
                        <b>Phone : </b> {order.billingInfo.phone}
                      </p>
                      <p>
                        <b>City : </b> {order.billingInfo.city}
                      </p>
                      <p>
                        <b>Country : </b> {order.billingInfo.country}
                      </p>
                      <p>
                        <b>Zip Code : </b> {order.billingInfo.postalCode}
                      </p>
                      <p>
                        <b>Address : </b> {order.shippingInfo.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          currentTab === "payment" && (
            <>
              <div className="col-md-4">

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          <b>Payment Method : </b> {order.paymentMethod}
                        </p>
                        <p>
                          <b>Item Price : </b> {order.itemsPrice}
                        </p>
                        <p>
                          <b>Shipping Price : </b> {order.shippingPrice}
                        </p>
                        <p>
                          <b>Total Price : </b> {order.totalPrice}
                        </p>
                        <p>
                          <b>Payment Status : </b>
                          {order.isPaid === true ? "Paid" : "Pending"}
                        </p>
                        <p>
                          <b>Order Status : </b>
                          {orderStatus}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </>
          )
        }
        {
          currentTab === "action" && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-label">Order Status</label>
                        {/* <Select options={orderStatusOptions}
                          value={
                            orderStatusOptions.find((option) => option.value === order.status)
                          }
                        /> */}
                        <Select
                          options={orderStatusOptions}
                          onChange={handleorderStatusOptions}
                          value={
                            orderStatus === null
                              ? ""
                              : orderStatusOptions.find((option) => {
                                return (
                                  option.value === orderStatus
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          currentTab === "customer" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-body">
                      {
                        order.customer ? (<>
                          <p>
                            <b>Name : </b> <Link to={`/customers/approve-cusotmer/${order.customer?._id}`}>{order.customer?.firstName} {order.customer?.lastName}</Link>
                          </p>
                          <p>
                            <b>Email : </b> {order.customer?.email}
                          </p>
                          <p>
                            <b>Customer Type : </b> {order.customer?.type}
                          </p>
                          <p>
                            <b>Phone : </b> {order.customer?.phone}
                          </p>
                        </>
                        ) : (
                          <>
                            <p className="text-center">
                              No Customer Info
                            </p>
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>
              </div>
            </>
          )

        }

      </div>
    </>
  );
};

export default OrderDeatil;
