import { React, useState } from 'react'
import { userLogin } from '../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'

const ForgetPass = () => {

    const redirect = useNavigate()
    const dispatch = useDispatch()
    const { isAuthenticated } = useSelector(state => state.Auth)
    const [data, setData] = useState({
        email: '',
    })

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleClick = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_HOST}/auth/forgot-password`, data)
            .then(res => {
                toast.success(res.data.message)
                redirect('/login')
            })
            .catch(err => {
                toast.error(err.response.data.error)
            })
    }
    return (
        <>
            <div className="layout-page" style={{ alignSelf: 'center' }}>
                <div className="container-xxl" >
                    <div className="authentication-wrapper authentication-basic container-p-y">
                        <div className="authentication-inner d-flex justify-content-center" >
                            <div className="card" style={{ width: '35%' }}>
                                <div className="card-body">
                                    <h4 className="mb-2 text-center">Forgot Password❓</h4>
                                    <p className="mb-4 text-center">Please enter your email for Password Reset Link</p>

                                    <form className="mb-3" onSubmit={handleClick}>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                required
                                                className="form-control"
                                                name="email"
                                                placeholder="Enter your email"
                                                autoFocus
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" d-flex justify-content-center">
                                            <button type='submit' className="btn btn-primary d-grid w-50 mt-3">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPass